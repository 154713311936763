import { fixFileName } from '../utils';

const FileDownloadLink = props => {
    const { file } = props;
    const { filename, id } = file;

    return (
        <span className="file-link">
            <a href={`/api/getfile.php?id=${id}`}>
                {fixFileName(filename)}
            </a>
        </span>
    );
}

export default FileDownloadLink;
