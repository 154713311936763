import { useEffect } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import classNames from 'classnames';

import './AdminView.css';
import UIStore from '../store';
import FileGroupSectionAdmin from './FileGroupSectionAdmin';
import DragAndDropFileUploader from './DragAndDropFileUploader';
import { ReactComponent as AlertIcon } from '../icons/alert.svg';
import {
    DROP_TYPE,
    DROP_TYPES_MAPPER_SHAPES_MAP,
    mapObjectShape,
} from "../utils";
import {
    getPlainObject,
    populateFileList,
    saveDifferences,
} from "../utils/mutations";

export const HOLDING_GROUP = Object.freeze({
    id: 0,
    group_name: "Holding area - files in this section will not be shown to publishers",
    sort_order: 0,
});

const AdminView = () => {
    const groups = UIStore.useState(s => s.groups);
    const files = UIStore.useState(s => s.files);
    const saving = UIStore.useState(s => s.saving);
    const hasGroups = groups.length > 0;

    useEffect(() => {
        populateFileList();
    }, []);

    const onDrop = (dropType, newGroupId, dropResult) => {
        const dropTypesMapperShape = DROP_TYPES_MAPPER_SHAPES_MAP.get(dropType);
        const { addedIndex, removedIndex, payload } = dropResult;

        if (addedIndex === removedIndex || addedIndex === null) {
            return
        }

        if (dropType === DROP_TYPE.GROUP) {
            UIStore.update(s => {
                const groupToMove = s.groups.splice(removedIndex - 1, 1)[0];
                s.groups.splice(addedIndex - 1, 0, groupToMove);
                s.groups.forEach((group, index) => group.sort_order = index + 1);
                saveDifferences(DROP_TYPE.GROUP, groups, getPlainObject(s.groups));
            });
        }

        if (dropType === DROP_TYPE.FILE) {
            const fileData = mapObjectShape(payload, dropTypesMapperShape);
            UIStore.update(s => {
                const { fileId } = fileData;
                const fileToMoveIndex = s.files.findIndex(file => file.id === fileId);
                const fileToMove = s.files.splice(fileToMoveIndex, 1)[0];
                fileToMove.group_id = newGroupId;
                const newGroupFiles = s.files.filter(file => file.group_id === newGroupId);
                const newInsertIndex = s.files.findIndex(file => file === newGroupFiles[addedIndex]);
                s.files.splice(newInsertIndex, 0, fileToMove);
                const groupMapper = new Map(s.groups.map(group => [group.id, 1]));
                groupMapper.set(HOLDING_GROUP.id, 1);
                s.files.forEach(file => {
                    const newSortOrder = groupMapper.get(file.group_id);
                    file.sort_order = newSortOrder;
                    groupMapper.set(file.group_id, newSortOrder + 1);
                });
                s.files.sort((a, b) => {
                    const groupIdDiff = a.group_id - b.group_id;
                    if (groupIdDiff !== 0) return groupIdDiff;
                    return a.sort_order - b.sort_order;
                });
                saveDifferences(DROP_TYPE.FILE, files, getPlainObject(s.files));
            });
        }
    };

    const addGroup = () => {
        const newGroupName = window.prompt('Enter a new group name');
        if (!newGroupName) return;

        const differences = {
            added: [{
                id: null,
                group_name: newGroupName,
                sort_order: groups.length + 1,
            }]
        };
        saveDifferences(DROP_TYPE.GROUP, differences, false);
    };

    return (
        <div className={classNames("admin", { saving: saving.size > 0 })}>
            <h1>Romsey Congregation Notice Board Admin</h1>
            <p>Use the controls to upload and reorder files, or move them between sections.</p>
            <div className="group-controls">
                <DragAndDropFileUploader />
                <button type="button" onClick={addGroup}>Add new section</button>
            </div>

            <Container
                onDrop={dropResult => onDrop(DROP_TYPE.GROUP, null, dropResult)}
                lockAxis="y"
                dragHandleSelector=".drag-handle"
                getChildPayload={index => groups[index]}
                groupName={DROP_TYPE.GROUP}
            >
                <FileGroupSectionAdmin
                    group={HOLDING_GROUP}
                    onDrop={onDrop}
                />
                {hasGroups && groups.map(group => (
                    <Draggable key={group.id}>
                        <FileGroupSectionAdmin
                            group={group}
                            onDrop={onDrop}
                        />
                    </Draggable>
                ))}
                {!hasGroups && (
                    <p>
                        <AlertIcon /> You currently have no sections. Sections are used to group documents. Use the 'Add new section' button above to add one or more sections.
                    </p>
                )}
            </Container>
        </div>
    );
};

export default AdminView;
