import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Login from './components/Login';
import UserView from './components/UserView';
import AdminView from './components/AdminView';
import { postIsLoggedIn, postLogout } from './api';
import UIStore from './store';
import { ReactComponent as LogoutIcon } from './icons/logout.svg';

const App = () => {

  useEffect(() => {
    const populateLoginData = async () => {
      const loggedInData = await postIsLoggedIn();
      const { ok = false, data: { loggedin = false, isadmin = false } } = loggedInData;
      if (!ok) {
        toast.error('Unable to verify login status.')
      }
      UIStore.update(s => {
        s.loggedIn = loggedin;
        s.isAdmin = isadmin;
      });
    }
    populateLoginData();
  }, []);

  const loggedIn = UIStore.useState(s => s.loggedIn);
  const isAdmin = UIStore.useState(s => s.isAdmin);

  const logoutHandler = async () => {
    await postLogout();
    UIStore.update(s => {
      s.loggedIn = false;
      s.isAdmin = false;
    })
  };

  return (
    <div className="App">
      {!loggedIn && (
        <Login />
      )}
      {loggedIn && (
        <span className="logout">
          <button type="button" title="Log out" className="btn btn-link" onClick={logoutHandler}><LogoutIcon className="icon icon-large" /></button>
        </span>
      )}
      {loggedIn && !isAdmin && (
        <UserView />
      )}
      {loggedIn && isAdmin && (
        <AdminView />
      )}
      <ToastContainer
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
}

export default App;
