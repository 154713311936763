import { useState } from 'react';
import { Container, Draggable } from "react-smooth-dnd";
import classNames from 'classnames';

import './FileGroupSection.css';
import { DROP_TYPE } from '../utils';
import { saveDifferences } from '../utils/mutations';
import FileListItemAdmin from './FileListItemAdmin';
import UIStore from '../store';
import DragHandle from './DragHandle';
import { HOLDING_GROUP } from './AdminView';
import { ReactComponent as AlertIcon } from '../icons/alert.svg';

const FileGroupSectionAdmin = props => {
    const { group, onDrop } = props;
    const { id, group_name, sort_order } = group;
    const files = UIStore.useState(s => s.files);
    const groups = UIStore.useState(s => s.groups);
    const groupNames = groups.map(group => group.group_name.toLowerCase());
    const groupFiles = files?.filter?.(file => file.group_id === id) ?? [];
    const [groupName, setGroupName] = useState(group_name);
    const [isEditingGroup, setIsEditingGroup] = useState(false);
    const [isUniqueName, setIsUniqueName] = useState(true);
    const [isValidName, setIsValidName] = useState(groupName.trim().length > 0);
    const hasFiles = groupFiles.length > 0;
    const isHoldingArea = id === HOLDING_GROUP.id;

    const editGroup = () => {
        setIsEditingGroup(true);
    };

    const changeGroupNameHandler = e => {
        const { value } = e.target;
        setGroupName(value);
        setIsValidName(value.trim().length > 0);
        setIsUniqueName(!groupNames.includes(value.trim().toLowerCase()));
    };

    const keypressHandler = e => {
        if (e.which === 13) {
            saveGroup();
        }
        e.stopPropagation();
    };

    const saveGroup = () => {
        UIStore.update(s => {
            const groupToChange = s.groups.find(g => g.id === id);
            groupToChange.group_name = groupName;
            saveDifferences(DROP_TYPE.GROUP, groups, s.groups);
        });
        setIsEditingGroup(false);
    };

    const deleteGroup = () => {
        UIStore.update(s => {
            s.groups = s.groups
                .filter(group => group.id !== id)
                .map(group => ({
                    ...group,
                    sort_order: group.sort_order > sort_order ? group.sort_order - 1 : group.sort_order,
                }));
            saveDifferences(DROP_TYPE.GROUP, groups, s.groups);
        })
        setIsEditingGroup(false);
    };

    const cancelSaveGroup = () => {
        setGroupName(group_name);
        setIsEditingGroup(false);
    };

    return (
        <div className={classNames("file-group-section", { 'holding-section': isHoldingArea })}>
            <h2>
                {!isHoldingArea && (
                    <DragHandle className="drag-handle" />
                )}
                {isHoldingArea && (
                    <span className="group-name">{groupName}</span>
                )}
                {!isHoldingArea && !isEditingGroup && (
                    <>
                        <span className="group-name">
                            {groupName}
                            {!hasFiles && (<span className="empty-group-warming">
                                <AlertIcon /> (will not be visible as has no files)
                            </span>)}
                        </span>
                        <button
                            type="button"
                            onClick={editGroup}
                            className="btn"
                            title="Edit the group name"
                        >Edit</button>

                        <button
                            type="button"
                            onClick={deleteGroup}
                            className="btn delete"
                            disabled={hasFiles}
                            title={`Delete group${hasFiles ? ' (only available when group is empty)' : ''}`}
                        >Delete</button>
                    </>
                )}
                {!isHoldingArea && isEditingGroup && (
                    <>
                        <span className="group-name form-group">
                            <input type="text" value={groupName} onChange={changeGroupNameHandler} onKeyPress={keypressHandler} />
                            {!isUniqueName && (
                                <span className="group-name-error">
                                    <AlertIcon /> a group with this name already exists</span>
                            )}
                            {!isValidName && (
                                <span className="group-name-error">
                                    <AlertIcon /> you can't use that group name</span>
                            )}
                        </span>
                        <button
                            type="button"
                            onClick={saveGroup}
                            className="btn"
                            disabled={!isValidName || !isUniqueName}
                        >Save</button>
                        <button type="button" onClick={cancelSaveGroup} className="btn btn-outline">Cancel</button>
                    </>
                )}
            </h2>

            {isHoldingArea && (<p className="holding-area-blurb">Use this area to store document for future display, or to temporarily remove a document from display.</p>)}

            <Container
                onDrop={dropResult => onDrop(DROP_TYPE.FILE, id, dropResult)}
                groupName={DROP_TYPE.FILE}
                lockAxis="y"
                dragHandleSelector=".drag-handle"
                getChildPayload={index => groupFiles[index]}
                render={ref => (
                    <div className="document-list" ref={ref}>
                        {groupFiles?.map?.(file => (
                            <Draggable key={file.id}>
                                <FileListItemAdmin
                                    innerRef={ref}
                                    file={file}
                                    groupId={id}
                                />
                            </Draggable>
                        ))}
                    </div>
                )}
            >
            </Container>
        </div>
    );
}

export default FileGroupSectionAdmin;
