const FileName = props => {
    const {
        id,
        value,
        setValue,
    } = props;

    return (
        <div className="form-group">
            <label htmlFor={`filename-${id}`}>File name</label>
            <input
                id={`filename-${id}`}
                type="text"
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </div>

    );
};

export default FileName;
