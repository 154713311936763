import { toast } from 'react-toastify';

import UIStore from '../store';

const TOAST = Object.freeze({
    SUCCESS: 'success',
    ERROR: 'error',
});

const toastMessage = (toastMessageType, message = null) => {
    if (!Object.values(TOAST).includes(toastMessageType)) {
        return;
    }
    if (message !== null) {
        toast[toastMessageType](message);
    }
}

const addToSavingStack = (url, requestId) => {
    UIStore.update(s => {
        s.saving.set(requestId, url);
    });
};

const removeFromSavingStack = requestId => {
    UIStore.update(s => {
        s.saving.delete(requestId);
    });
};

const fetchData = async (url, responseMethod = 'json', body = null, successMessage = null, errorMessage = null) => {
    const requestId = Math.floor((Math.random() * new Date().getTime()));
    addToSavingStack(url, requestId);
    const headers = new Headers({
        "X-Requested-With": "XMLHttpRequest"
    });

    try {
        const isFormData = body?.constructor === FormData;

        const response = await fetch(
            url,
            {
                method: 'POST',
                ...(body !== null && { body: isFormData ? body : JSON.stringify(body) }),
                headers,
            }
        );
        const responses = new Map([
            [400, "badRequest"],
            [401, "unauthorized"],
            [403, "notAllowed"],
            [404, "notFound"],
            [405, "badMethod"],
        ]);
        if (responses.has(response.status)) {
            const error = responses.get(response.status);
            removeFromSavingStack(requestId);
            toastMessage(TOAST.ERROR, errorMessage);
            return { ok: false, error };
        }
        if (response.ok) {
            try {
                const data = await response[responseMethod]();
                removeFromSavingStack(requestId)
                toastMessage(TOAST.SUCCESS, successMessage);
                return { ok: true, data };
            } catch (error) {
                removeFromSavingStack(requestId);
                toastMessage(TOAST.ERROR, errorMessage);
                return { ok: false, error };
            }
        }
    } catch (error) {
        removeFromSavingStack(requestId);
        toastMessage(TOAST.ERROR, errorMessage);
        return { ok: false, error };
    }
}

const getJSON = async (url, successMessage = null, errorMessage = null) => {
    const result = await fetchData(url, 'json', null, successMessage, errorMessage);
    return result;
}
const postJSON = async (url, body = null, successMessage = null, errorMessage = null) => {
    const result = await fetchData(url, 'json', body, successMessage, errorMessage);
    return result;
}

export const getFileList = () => getJSON('/api/getfilelist.php', null, "There was a problem updating the document list");
export const getFileData = id => getJSON(`/api/getfile.php?id=${id}`, null, "There was a problem fetching the document.");
export const postLogin = (username, password) => postJSON('/api/login.php', { username, password }, null, null);
export const postIsLoggedIn = () => postJSON('/api/isloggedin.php', null, null, null);
export const postLogout = () => postJSON('/api/logout.php', null, "You have logged out successfully", "There was a problem logging you out");
export const postUploadFile = (formData, successMessage, errorMessage) => postJSON('/api/uploadfile.php', formData, successMessage, errorMessage);
export const postUpdate = (type, differences) => postJSON('/api/update.php', { type, differences }, "Your changes were saved successfully", "There was a problem saving your changes");
