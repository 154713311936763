import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import "./DragAndDropFileUploader.css";
import { populateFileList } from "../utils/mutations";
import { postUploadFile } from '../api';
import { ReactComponent as UploadIcon } from '../icons/upload.svg';

const ACCEPTED_FILE_TYPES = ['application/pdf'];
const MAX_FILE_SIZE = 1024 * 1024 * 2;

const fileTypeIsOK = file => {
    const typeOK = ACCEPTED_FILE_TYPES.includes(file.type);
    if (!typeOK) {
        toast.error(`${file.name} is not a PDF file. Only PDF files are allowed.`);
    }
    return typeOK;
}
const fileSizeIsOK = file => {
    const sizeOK = file.size > 0 && file.size <= MAX_FILE_SIZE;
    if (!sizeOK) {
        toast.error(`${file.name} is bigger than the maximum size allowed (${MAX_FILE_SIZE} bytes).`);
    }
    return sizeOK;
}

const uploadFile = file => {
    const formData = new FormData();
    formData.append('file', file);
    return postUploadFile(formData, `${file.name} uploaded successfully`, `There was a problem uploading ${file.name}`);
};

const DragAndDropFileUploader = () => {
    const onDrop = async acceptedFiles => {
        if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
            const filesToUpload = acceptedFiles.filter(file =>
                fileTypeIsOK(file) && fileSizeIsOK(file)
            );

            await filesToUpload.reduce(async (previousPromise, file) => {
                const uploadResult = await previousPromise;
                const result = await uploadFile(file);
                uploadResult.push(result);
                return uploadResult;
            }, Promise.resolve([]));
            populateFileList();
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div {...getRootProps({
            className: `file-drop-zone ${isDragActive ? 'drag-active' : ''}`
        })}>
            <input {...getInputProps()} />
            <UploadIcon className="upload-icon" />
            <div>
                {isDragActive && (<>Drop </>)}
                {!isDragActive && (<>Click here, or drag and drop </>)}
                files to upload them to the holding area.
            </div>
        </div>
    );
}

export default DragAndDropFileUploader;
