import './FileGroupSection.css';
import FileListItem from './FileListItem';
import UIStore from '../store';

const FileGroupSection = props => {
    const { group } = props;
    const files = UIStore.useState(s => s.files);
    const groupFiles = files?.filter?.(file => file.group_id === group.id) ?? [];
    const showGroup = Array.isArray(groupFiles) && groupFiles.length > 0;

    return showGroup ? (
        <div className="file-group-section">
            <h2>
                <span className="group-name">{group.group_name}</span>
            </h2>

            <div className="document-list">
                {groupFiles?.map?.(file => (
                    <FileListItem
                        key={file.id}
                        file={file}
                        groupId={group.id}
                    />
                ))}
            </div>
        </div>
    ) : null;
}

export default FileGroupSection;
