import { useEffect } from "react";
import UIStore from '../store';
import FileGroupSection from './FileGroupSection';
import { populateFileList } from "../utils/mutations";

const UserView = () => {
    const groups = UIStore.useState(s => s.groups);
    const files = UIStore.useState(s => s.files);

    useEffect(() => {
        populateFileList();
    }, []);

    const hasFiles = files.length > 0;

    return (
        <div>
            <h1>Romsey Congregation Notice Board</h1>
            {!hasFiles && (
                <p>There are no documents available at the moment.</p>
            )}
            {hasFiles && groups?.map(group => (
                <FileGroupSection
                    key={group.id}
                    group={group}
                />
            ))}
        </div>
    );
};

export default UserView;
