import React, { useState } from 'react';
import { toast } from 'react-toastify';

import './Login.css';
import { postLogin } from '../api/index';
import UIStore from '../store';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginInProgress, setLoginInProgress] = useState(false);

    const onLoginHandler = async (e) => {
        e.preventDefault();
        setLoginInProgress(true);
        const loginResult = await postLogin(username, password);
        const { ok = false, data: { loggedin = false, isadmin = false } } = loginResult;
        if (ok && loggedin) {
            toast.success('Logged in successfully.');
        } else {
            toast.error('Login failed.')
        }
        setLoginInProgress(false);
        UIStore.update(s => {
            s.loggedIn = loggedin;
            s.isAdmin = isadmin;
        });
    }

    return (
        <div className="login">
            <img src="images/kh.jpg" width="100" height="100" alt="" />
            <h1>Log in</h1>
            <form method="POST" onSubmit={onLoginHandler}>
                <div className="form-group">
                    <label htmlFor="username">User name</label>
                    <input type="text" id="username" name="username" onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="form-group login-button">
                    <button type="submit" disabled={loginInProgress}>Log in</button>
                </div>
            </form>
        </div>
    )
};

export default Login;
