import { Store, registerInDevtools } from 'pullstate';

const UIStore = new Store({
    loggedIn: false,
    isAdmin: false,
    groups: [],
    files: [],
    saving: new Map(),
    pdfDataURL: null,
});

export default UIStore;

registerInDevtools({
    UIStore,
});

