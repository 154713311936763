export const DROP_TYPE = Object.freeze({
    FILE: 'file',
    GROUP: 'group',
});

export const MAPPER_SHAPES = Object.freeze({
    FILE_PAYLOAD: Object.freeze({
        id: 'fileId',
        group_id: 'oldGroupId',
        filename: 'fileName',
    }),
    GROUP_PAYLOAD: Object.freeze({
        id: 'groupId',
        group_name: 'groupName',
    }),
});

export const DROP_TYPES_MAPPER_SHAPES_MAP = new Map([
    [DROP_TYPE.FILE, MAPPER_SHAPES.FILE_PAYLOAD],
    [DROP_TYPE.GROUP, MAPPER_SHAPES.GROUP_PAYLOAD],
]);

export const mapObjectShape = (originalObject, mapperShape) => {
    const originalObjectEntries = Object.entries(originalObject);
    const desiredKeys = Object.keys(mapperShape);
    const mappedEntries = originalObjectEntries
        .filter(([key, _value]) => desiredKeys.includes(key))
        .map(([key, value]) => [mapperShape[key], value]);
    return Object.fromEntries(mappedEntries);
};

export const formatDate = stringDate => {
    const [t, ...d] = stringDate.split(/[-\sT]/).reverse();
    return `${d.join('/')} ${t}`;
};

export const fixFileName = fileName => fileName.replace(/\.pdf$/i, '');
