import { useState } from 'react';

import './FileListItem.css';
import UIStore from '../store';
import FileDownloadLink from './FileDownloadLink';
import FileDetailsAdmin from './FileDetailsAdmin';
import { ReactComponent as DocumentIcon } from '../icons/file.svg';
import DragHandle from './DragHandle';
import { DROP_TYPE } from '../utils';
import { saveDifferences } from '../utils/mutations';

const FileListItemAdmin = props => {
    const { file } = props;
    const files = UIStore.useState(s => s.files);

    const [isEditingFile, setIsEditingFile] = useState(false);

    const deleteFileHandler = async () => {
        const deleteId = file.id;
        // eslint-disable-next-line no-restricted-globals
        const confirmation = confirm(`Are you sure you want to delete the file "${file?.filename}"?`);
        if (!confirmation) return;
        UIStore.update(s => {
            s.files = s.files.filter(file => file.id !== deleteId);
            saveDifferences(DROP_TYPE.FILE, files, s.files);
        });
    };

    return (
        <div className="document-list-item">
            <DragHandle className="drag-handle" />
            <DocumentIcon
                className="icon icon-large"
            />
            {!isEditingFile && (<>
                <FileDownloadLink file={file} />
                <button className="btn btn-primary" onClick={() => setIsEditingFile(true)}>Edit</button>
                <button className="btn delete" onClick={deleteFileHandler}>Delete</button>
            </>)}
            {isEditingFile && (
                <FileDetailsAdmin file={file} setIsEditingFile={setIsEditingFile} />
            )}
        </div>
    );
}

export default FileListItemAdmin;
