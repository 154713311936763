import './FileListItem.css';
import FileDownloadLink from './FileDownloadLink';
import { ReactComponent as DocumentIcon } from '../icons/file.svg';

const FileListItem = ({ file }) => (
    <div className="document-list-item">
        <DocumentIcon
            className="icon icon-large"
        />
        <FileDownloadLink file={file} />
    </div>
);

export default FileListItem;
