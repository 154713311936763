import { useState } from "react";

import "./FileDetailsAdmin.css";
import UIStore from '../store';
import { DROP_TYPE } from "../utils";
import { saveDifferences } from "../utils/mutations";
import FileName from './form-components/FileName';

const FileDetailsAdmin = props => {
    const { file, setIsEditingFile } = props;
    const { id: fileId, filename } = file;
    const files = UIStore.useState(s => s.files);

    const [fileName, setFileName] = useState(filename ?? '');

    const saveFileDetails = () => {
        UIStore.update(s => {
            const editingFile = s.files.find(file => file.id === fileId);
            if (!editingFile) {
                return;
            }
            editingFile.filename = fileName;
            saveDifferences(DROP_TYPE.FILE, files, s.files);
        });
        setIsEditingFile(false);
    };

    return (
        <div className="file-details-admin">
            <FileName
                id={fileId}
                value={fileName}
                setValue={setFileName}
            />
            <button type="button" onClick={saveFileDetails} className="btn btn-primary">Save</button>
            <button type="button" onClick={() => setIsEditingFile(false)} className="btn btn-outline">Cancel</button>
        </div>
    );
};

export default FileDetailsAdmin;
