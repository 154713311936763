import { toast } from "react-toastify";

import UIStore from '../store';
import { getFileList, postUpdate } from '../api';

const compareObjectArrays = (oldObjectArray, newObjectArray) => {
    const ID_FIELD = 'id';
    const getIds = arr => arr.map(obj => obj[ID_FIELD]).filter(id => id !== null);
    const newIds = getIds(newObjectArray);
    const oldIds = getIds(oldObjectArray);
    const added = newObjectArray.filter(obj => obj[ID_FIELD] === null);
    const deleted = oldObjectArray.filter(obj => !newIds.includes(obj[ID_FIELD]));
    const updated = newObjectArray.reduce((acc, obj) => {
        if (!oldIds.includes(obj[ID_FIELD])) return acc;
        const oldObj = oldObjectArray.find(oldObj => oldObj[ID_FIELD] === obj[ID_FIELD]);
        if (Object.keys(oldObj).some(key => oldObj[key] !== obj[key])) return [...acc, obj];
        return acc;
    }, []);
    const hasDifferences = added.length > 0 || deleted.length > 0 || updated.length > 0;
    return hasDifferences ? {
        added,
        deleted,
        updated,
    } : null;
};

export const getPlainObject = obj => JSON.parse(JSON.stringify(obj));

export const populateFileList = async () => {
    const fileList = await getFileList();
    const { ok = false, data: { groups = [], files = [] } } = fileList;
    if (!ok) {
        toast.error('Unable to load document information. Please try refreshing the page.');
    }
    UIStore.update(s => {
        s.groups = groups;
        s.files = files;
    });
};

export const saveDifferences = async (type, originalValuesOrDifferencesObject, draftValuesProxyObjectOrFalse) => {
    const differences = draftValuesProxyObjectOrFalse === false
        ? originalValuesOrDifferencesObject
        : compareObjectArrays(originalValuesOrDifferencesObject, getPlainObject(draftValuesProxyObjectOrFalse));
    if (differences) {
        await postUpdate(type, differences);
        populateFileList();
    }
};